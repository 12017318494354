
/**
 * Keys of all generated SVG sprite symbols.
 */
export type NuxtSvgSpriteSymbol =
  | 'add'
  | 'alva'
  | 'arrow-east-thin'
  | 'arrow-east'
  | 'arrow-north-east'
  | 'arrow-south'
  | 'arrow-west'
  | 'attachment'
  | 'baselstab'
  | 'bettingenkelch'
  | 'breadcrumb'
  | 'calendar'
  | 'card'
  | 'caret'
  | 'cart'
  | 'check'
  | 'circle-check'
  | 'circle-error'
  | 'circle-ko'
  | 'circle-ok'
  | 'circle-person'
  | 'circle-warning'
  | 'close'
  | 'dots'
  | 'download'
  | 'easy-to-read'
  | 'facebook'
  | 'file'
  | 'hands'
  | 'hinweise_no_rounded'
  | 'hinweise_rounded'
  | 'instagram'
  | 'linkedin'
  | 'location'
  | 'mail'
  | 'pen'
  | 'phone'
  | 'pin'
  | 'play'
  | 'print'
  | 'reload'
  | 'search'
  | 'send-mail'
  | 'share'
  | 'threads'
  | 'trash'
  | 'x_formerly_twitter'
  | 'youtube'

export const SPRITE_PATHS = {"default":"/_nuxt/sprite-default.kbvzuoMzes.svg"}
